.Rights {
  .Title {
    margin-left: 40px;
    margin-top: 51px;
  }

  .Rights-Table {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-top: 28px;

    .row {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      margin: 0;
      margin-bottom: 1px;
      height: 50px;

      &:nth-child(2n) {
        background-color: $odd;
      }

      .column {
        font-size: 18px;
        line-height: 21px;
        color: $text;

        &:first-child {
          padding-left: 40px;
          width: 350px;
        }

        &:nth-child(2) {
          margin-right: 111px;
        }

        label {
          position: relative;

          input {
            position: absolute;
            top: 15px;
            z-index: -1;
            opacity: 0;

            &:checked+span::before {
              background-image: url("../svg/tick.svg");
              background-size: 15px;
            }
          }

          span {
            cursor: pointer;
            
            &::before {
              content: "";
              display: block;
              width: 25px;
              height: 25px;
              background-color: white;
              border: 1px solid $accent;
              background-repeat: no-repeat;
              background-position: center center;
              background-size: 50% 50%;
              -moz-transition: all 0.1s ease 0s;
              -webkit-transition: all 0.1s ease 0s;
              -o-transition: all 0.1s ease 0s;
              transition: all 0.1s ease 0s;
            }

            &:active::before {
              -moz-transform: scale(0.8);
              -ms-transform: scale(0.8);
              -webkit-transform: scale(0.8);
              -o-transform: scale(0.8);
              transform: scale(0.8);
            }
          }
        }
      }
    }
    
    .head {
      .column {
        color: $light-gray;

        &:nth-child(2) {
          margin-right: 49px;
        }
      }
    }
  }
}