@import "vars";
@import "fonts";

body {
  margin: 0;
}

* {
  font-family: $default-font;
}

textarea:disabled {
  background-color: $odd;
}

input:disabled {
  color: $light-gray;
}

.Alert {
  position: fixed;
  bottom: 50px;
  display: flex;
  align-items: center;
  border-radius: 4px;
  padding: 10px 10px 10px 20px;
  background-color: #ffeebd;
  justify-content: space-between;
  -moz-transition: all 0.8s;
  -webkit-transition: all 0.8s;
  -o-transition: all 0.8s;
  transition: all 0.8s;
  z-index: 100000;
  color: #ca9e24;

  &__close {
    width: 30px;
    display: flex;
    height: 30px;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    .close {
      background-color: #e6b939;
      width: 10px;
      height: 10px;
    }

    &:hover {
      .close {
        background-color: #e68d39;
      }
    }
  }

  &__text {
    margin-right: 15px;
    position: relative;
    top: -2px;
  }
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  border-radius: 4px;
  background-color: $odd;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: $light-gray;
  border: 3px solid $odd;
}

::-webkit-calendar-picker-indicator {
  margin-right: 7px;
}

.Title {
  font-size: 18px;
  line-height: 21px;
  color: $text;
  font-weight: bold;
}

@import "bootstrap-grid.min";
@import "header";
@import "users";
@import "edit";
@import "rights";
@import "request";
@import "main-table";
@import "authorization";
@import "custom-tooltip";
@import "main-content";
@import "request-purchase";
@import "request-stock";
@import "accounting";
@import "store";
@import "lower-table";
@import "users-list";
@import "rights-popup";
@import "request-container";
@import "account-creating-card";
@import "storage-popup";
