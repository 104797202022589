.CustomTooltip {
  padding: 20px 0 20px 35px;
  border: 1px $light-gray solid;
  font-size: 14px;
  display: inline-block;

  &__title {
    font-weight: bold;
    font-size: 18px;
  }

  &__content {
    display: flex;
    flex-wrap: wrap;
    margin: 10px 0 20px;

    .CustomTooltip__item {
      position: relative;
      margin: 30px 35px 0 0;

      &-title {
        font-weight: bold;
        position: absolute;
        top: -30px;
      }

      &-subtitle {
        color: $dark-gray;
        margin-bottom: 10px;
      }
    }
  }

  &__undeground {
    display: flex;

    .CustomTooltip__button {
      margin-right: 85px;

      a {
        text-decoration: none;
      }
    }

    .CustomTooltip__button-tool {
      color: $dark-gray;
    }
  }

  &--information {
    .CustomTooltip__item:first-child {
      margin-right: 140px;
    }

    .CustomTooltip__button {

      a {
        color: $dark-gray;
      }
    }
  }

  &--account-create {
    width: 620px;

    .CustomTooltip__button {
      a {
        color: $accent;
      }
    }
  }

  &--account-information {
    width: 620px;

    .CustomTooltip__content {
      margin-top: -10px;
    }
  }

  &--additional-information {
    width: 620px;

    .CustomTooltip__item {
      width: 40%;

      &-content {
        overflow: hidden;
      }
    }

    .CustomTooltip__content {
      margin-top: -10px;
    }
  }
}
