.Creating,
.Request-purchase,
.Request-stock,
.Request-Creating, {
  .create-button {
    .Button {
      @include Button;
      margin-top: 20px;
      outline: none;

      &:disabled {
        border-color: $dark-gray;
        color: $dark-gray;
      }
    }
  }

  .nameInputPopup {
    input {
      border: 1px solid $light-gray;
      padding: 5px;
    }
  }

  .delete-button-container {
    display: flex;
    justify-content: flex-end;
    .buttonDelete {
      margin-top: 20px;
      cursor: pointer;
      margin-left: 29px;
      -moz-transition: all 0.2s;
      -webkit-transition: all 0.2s;
      -o-transition: all 0.2s;
      transition: all 0.2s;
      color: $dark-gray;

      &:hover {
        color: $red;
      }
    }
  }

  .request {
    display: grid;
    grid-template-areas:
      "order-number request-state date-of-creating"
      "object organization address"
      "closing-date requester calculationer"
      "contract-number billing-date documents"
      "note note note";
    grid-gap: 20px;
    margin-top: 30px;

    .top {
      display: flex;
      justify-content: flex-start;
      color: $light-gray;
      font-size: 14px;
      line-height: 16px;
      margin-bottom: 5px;

      .add-to-base {
        margin-left: 27px;
        color: $light-gray;
        cursor: pointer;

        &:hover {
          color: $accent;
        }

        &--hidden {
          visibility: hidden;
        }
      }
    }

    input,
    textarea {
      position: relative;
      margin: 0;
      padding: 8px 0 8px 14px;
      font-size: 18px;
      line-height: 21px;
      border: 1px solid $light-gray;
      resize: none;
      outline: none;
      width: 350px;
      transition: all 0.2s ease 0s;

      &:focus {
        border-color: $accent;
      }

      &:valid:not(:focus) {
        border-color: transparent;
        padding-left: 0;
      }

      &:invalid {
        box-shadow: none;
      }

      &:disabled {
        background-color: white;
        border-color: transparent;
      }

      &::placeholder {
        color: $light-gray;
      }
    }

    .date {
      position: relative;

      ::-webkit-calendar-picker-indicator {
        cursor: pointer;
      }
      input {
        width: 170px;
        cursor: pointer;

        &:valid:not(:focus) + span {
          visibility: visible;
          padding-left: 0;
        }

        &:invalid {
          color: $light-gray;
        }
      }

      span {
        visibility: hidden;
        position: absolute;
        left: 0;
        bottom: -8px;
        width: 100%;
        height: 39px;
        padding: 8px 0 8px 14px;
        font-size: 18px;
        line-height: 21px;
        background-color: white;
        color: $text;
        border: 1px solid transparent;
        cursor: pointer;
        transition: padding-left 0.2s ease 0s;
      }
    }

    .request-state,
    .date-of-creating,
    .address,
    .requester {
      input {
        color: $dark-gray;

        &:focus:valid {
          color: $text;
        }
      }
    }

    .order-number,
    .request-state {
      input {
        color: $dark-gray;
        cursor: default;
        padding-left: 0;
      }
    }

    .object,
    .organization,
    .address,
    .requester,
    .calculationer {
      position: relative;

      .autocomplete {
        display: none;
        position: absolute;
        z-index: 1000;
        width: fit-content;
        background-color: white;
        color: $text;
        border: 1px solid $light-gray;
        box-shadow: 0px 20px 60px rgba(0, 0, 0, 0.1);
        height: fit-content;
        max-height: 250px;
        overflow: auto;

        .completion {
          display: flex;
          justify-content: space-between;
          align-items: center;
          min-height: 40px;
          height: fit-content;
          padding-right: 14px;
          padding-left: 14px;

          &:hover {
            background-color: $odd;
            cursor: pointer;

            .options {
              .edit,
              .delete {
                color: $dark-gray;
              }

              .edit:hover {
                color: $accent;
              }

              .delete:hover {
                color: $red;
              }
            }
          }

          .text {
            width: 400px;
            padding-top: 8px;
            padding-bottom: 8px;
          }

          .options {
            display: flex;
            justify-content: space-between;
            width: 180px;
            margin-left: 10px;
            font-size: 14px;
            line-height: 16px;

            .edit,
            .delete {
              color: transparent;
            }
          }
        }

        &:hover {
          display: block;
        }

        &:empty {
          border: none;
        }
      }

      & input:focus,
      textarea:focus + .autocomplete {
        display: block;
      }
    }

    .organization {
      .autocomplete {
        .completion {
          .text {
            width: 350px;
          }
        }
      }
    }

    .calculationer {
      .autocomplete {
        .completion {
          .text {
            width: 210px;
          }
        }
      }
    }

    .address,
    .requester {
      .autocomplete {
        right: 6px;
        .completion {
          .text {
            width: 320px;
          }
        }
      }
    }

    .note {
      textarea {
        width: 310%;
        height: 140px !important;
        padding: 8px 0 8px 14px !important;
        border: 1px solid $light-gray !important;
        font-size: 18px;
        line-height: 21px;
        outline: none;
        resize: none;

        &:focus {
          border-color: $accent !important;
        }
      }
    }

    .close-request {
      margin-top: 29px;
      color: $accent;
      cursor: pointer;
    }

    .documents {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 280px;

      .document,
      .total {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-size: 18px;
        line-height: 21px;
        color: $dark-gray;
        cursor: pointer;

        &:hover {
          color: $text;

          .excel {
            background-color: $green;
          }
        }

        .excel {
          width: 29px;
          height: 29px;
          margin-right: 15px;
          background-color: $dark-gray;
        }
      }
    }
  }

  .Popup {
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.4);
    width: 100vw;
    height: 200vh;
    z-index: 1000;
    visibility: hidden;
    transition: visibility 0.4s ease 0s;

    &.show {
      visibility: visible;

      .window {
        top: 20%;
        transition: top 0.4s ease 0s;
      }
    }

    .window {
      position: absolute;
      top: -20%;
      left: 50%;
      padding: 45px;
      background-color: white;
      transform: translate(-50%, -50%);
      transition: top 0.4s ease 0s;

      .oldValue {
        margin-top: 20px;
        width: 350px;
        color: $text;
        font-size: 14px;
      }

      .Input {
        margin-top: 15px;

        .top {
          margin-bottom: 5px;

          .title {
            font-size: 14px;
            line-height: 16px;
            color: $dark-gray;
          }
        }

        input {
          position: relative;
          right: 14px;
          padding: 8px 0 8px 14px;
          font-size: 14px;
          line-height: 16px;
          border: 1px solid $light-gray;
          outline: none;
          width: 350px;
          color: $text;

          &:focus {
            border-color: $accent;
          }

          &:disabled {
            background-color: white;
            border-color: transparent;
          }

          &::placeholder {
            color: $light-gray;
          }

          &:invalid {
            border-color: red;
          }
        }
      }

      .field,
      .value,
      .answers {
        margin-top: 15px;
      }

      .answers {
        display: flex;
        justify-content: space-between;
        width: 100%;

        .positive,
        .negative {
          cursor: pointer;
        }

        .positive {
          color: $light-gray;
        }

        .negative {
          color: $accent;
        }
      }

      .buttons {
        display: flex;
        justify-content: space-between;
        margin-top: 15px;
        width: 145px;
        font-size: 14px;
        line-height: 16px;

        .save,
        .cancel {
          cursor: pointer;
        }

        .save {
          color: $accent;
        }

        .cancel {
          color: $dark-gray;
        }
      }
    }
  }
}

.Request-Creating {
  .address {
    .autocomplete {
      right: 6px;
    }
  }

  .organization {
    .autocomplete {
      left: 0;
    }
  }

  .Lower-table-header {
    .name {
      margin-right: 120px;
    }
  }
}

.Creating {
  .name {
    max-width: 180px;
    min-width: 180px;
  }
}

.View-Application {
  .Creating .request {
    input:disabled,
    textarea:disabled {
      color: #969696 !important;
      padding-left: 0;
    }

    span {
      visibility: visible !important;
      color: #969696 !important;
      padding-left: 0 !important;
      cursor: default !important;
    }
  }
}

.document,
.total {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 18px;
  line-height: 21px;
  color: $dark-gray;
  cursor: pointer;
  margin-top: 10px;

  &:hover {
    color: $text;

    .excel {
      background-color: $green;
    }
  }

  .excel {
    width: 29px;
    height: 29px;
    margin-right: 15px;
    background-color: $dark-gray;
  }
}
