.accountCreatingCard {
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.4);
  width: 100vw;
  height: 100vh;
  visibility: hidden;
  transition: visibility 0.4s ease 0s;

  input {
    border: 1px solid $light-gray;
    padding-left: 5px;

    &:focus {
      border: 1px solid $accent;
    }
  }

  .fillFields {
    color: $dark-gray;
    margin-top: 20px;
  }

  .window {
    width: 800px;
    .card-title {
      font-size: 18px;
      line-height: 21px;
      font-style: normal;
      font-weight: bold;
      margin-bottom: 50px;
    }
    .conclusion {
      display: flex;
      justify-content: space-between;
      .save-data {
        font-size: 14px;
        color: $accent;
        margin-top: 20px;
        cursor: pointer;
      }
      .cancel {
        font-size: 14px;
        line-height: 16px;
        color: $red;
        margin-top: 20px;
        margin-right: 35px;
        cursor: pointer;
      }
    }
    .table {
      display: flex;
      flex-wrap: wrap;
      .serial-Number-title {
        font-size: 14px;
        line-height: 16px;
        color: $dark-gray;
        margin-right: 30px;
        margin-bottom: 10px;

        .serial-Number {
          display: flex;
          align-items: center;
          height: 36px;
          font-size: 14px;
          line-height: 16px;
          color: $text;
          border: 1px solid transparent;
          margin-top: 8px;
        }
      }
      .acc-Num-title {
        font-size: 14px;
        line-height: 16px;
        color: $dark-gray;
        margin-right: 30px;
        margin-bottom: 10px;
        .acc-Num {
          width: 153px;
          height: 36px;
          margin-top: 8px;
        }
      }

      .acc-Sum-title {
        font-size: 14px;
        line-height: 16px;
        color: $dark-gray;
        margin-right: 30px;
        margin-bottom: 10px;
        .acc-Sum {
          width: 153px;
          height: 36px;
          margin-top: 8px;
        }
      }

      .acc-Date-title {
        font-size: 14px;
        line-height: 16px;
        color: $dark-gray;
        margin-right: 30px;
        margin-bottom: 10px;
        .acc-Date {
          width: 153px;
          height: 36px;
          margin-top: 8px;
          cursor: pointer;
        }
      }

      .acc-Cust-title {
        font-size: 14px;
        line-height: 16px;
        color: $dark-gray;
        margin-right: 30px;
        margin-bottom: 10px;
        .acc-Cust {
          width: 323px;
          height: 36px;
          margin-top: 8px;
        }
      }

      .acc-Sup-title {
        font-size: 14px;
        line-height: 16px;
        color: $dark-gray;
        margin-right: 30px;
        margin-bottom: 10px;
        .acc-Sup {
          width: 323px;
          height: 36px;
          margin-top: 8px;
        }
      }
    }
  }
}
