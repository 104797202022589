@mixin icon($width, $height, $name) {
  display: block;
  text-indent: -9999px;
  width: #{$width}px;
  height: #{$height}px;
  background-color: $text;
  -webkit-mask-image: url(../../svg/#{$name}.svg);
  mask-image: url(../../svg/#{$name}.svg);
  mask-size: contain;
  mask-position: center;
  mask-repeat: no-repeat;
}

@mixin Button {
  padding: 1em 4.2em;
  border: 1px solid $accent;
  background-color: #fff;
  color: $accent;
  font-size: 18px;
  line-height: 21px;
  user-select: none;
  background-color: white;
  
  -moz-transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  transition: all 0.2s ease;
  width: fit-content;

  &:hover:not(:disabled) {
    background-color: $accent;
    color: white;
    cursor: pointer;
  }

  &:active:not(:disabled) {
    -moz-transform: scale(0.95);
    -ms-transform: scale(0.95);
    -webkit-transform: scale(0.95);
    -o-transform: scale(0.95);
    transform: scale(0.95);
  }
}
