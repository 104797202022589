.Header {
  display: grid;
  grid-template-columns: 1fr 167px 3fr 1fr;
  grid-gap: 40px;
  grid-template-rows: 60px;
  align-items: center;

  .title,
  .subtitle,
  .user,
  .backButton {
    display: flex;
    align-items: center;
    font-size: 18px;
    line-height: 21px;
    font-weight: bold;
    color: $text;

    span {
      position: relative;
      bottom: 2px;
      left: 1px;
      color: #00b2ff;
    }
  }

  select {
    overflow: hidden;
    font-size: 18px;
    line-height: 21px;
    color: $text;
    height: 36px;
    padding: 5px 50px 5px 16px !important;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: 1px solid $light-gray;
    border-radius: 0;
    background-color: transparent;
    cursor: pointer;
    outline: none;
    background-image: url("../svg/arrow.svg");
    background-repeat: no-repeat;
    background-position: top 55% right 10px;
  }

  .title {
    justify-self: right;
  }

  .subtitle {
    justify-self: left;
  }

  .user,
  .backButton {
    color: $dark-gray;
    font-weight: normal;
  }

  .user {
    justify-self: right;
  }

  .backButton {
    justify-self: left;

    &:hover {
      color: $accent;
      cursor: pointer;
    }
  }
}
