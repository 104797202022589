.Main-table {
  .main-table-filters {
    padding: 0;
    margin-top: 10px;

    .menu {
      display: flex;
      justify-content: space-between;
      width: 150px;
      font-size: 14px;

      .allAccounts,
      .archive {
        font-weight: bold;
        color: $light-gray;
        cursor: pointer;

        &:hover {
          color: $accent;
        }

        &--active {
          color: $text;
        }
      }
    }

    .filters {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-end;
      height: 36px;
      margin-top: 20px;

      .Input {
        margin-right: 20px;
        .top {
          display: none;
        }
        input {
          position: relative;
          margin: 0;
          padding: 8px 0 8px 14px;
          font-size: 14px;
          line-height: 16px;
          border: 1px solid $light-gray;
          outline: none;
          width: 130px;
          transition: all 0.2s ease 0s;
          color: $text;

          &:focus {
            border-color: $accent;
          }

          &::placeholder {
            color: $light-gray;
          }
        }
      }

      .Select {
        position: relative;

        select {
          width: 110px;
          font-size: 14px;
          line-height: 16px;
          color: $text;
          padding: 8px 0 8px 14px;
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;
          border: 1px solid $light-gray;
          border-radius: 0;
          background-color: transparent;
          cursor: pointer;
          outline: none;
        }

        .arrow {
          z-index: -1;
          position: absolute;
          right: 10px;
          top: 0px;
          width: 11px;
          background-color: $light-gray;
        }
      }

      .Button {
        @include Button;
        padding: 0.393em 1.5em;
        font-size: 14px;
      }
    }
  }

  .title {
    display: grid;
    grid-template-columns: 0.3fr 2fr 2fr 1fr 1fr 1fr;
    grid-column-gap: 20px;
    align-items: center;
    color: $light-gray;
    font-size: 14px;
    margin-top: 40px;
    margin-bottom: 20px;
    padding-left: 20px;
  }

  .Main-alter {
    & > div:nth-child(2n + 1) {
      background-color: $odd;
    }
    .Main-Str {
      display: grid;
      grid-template-columns: 0.3fr 2fr 2fr 1fr 1fr 1fr;
      grid-column-gap: 20px;
      align-items: center;
      font-size: 14px;
      background-color: white;
      height: 70px;
      width: 100%;
      padding-left: 20px;
      position: relative;

      .removeAccount-block {
        position: absolute;
        height: 30px;
        display: flex;
        align-items: center;
        width: 30px;
        top: 21px;
        right: -30px;

        .removeAccount {
          width: 10px;
          height: 10px;
          cursor: pointer;
          display: none;
          margin: 0 auto;

          &:hover {
            background-color: $red;
          }
        }
      }

      &:hover {
        .removeAccount-block {
          .removeAccount {
            display: block;
          }
        }
      }

      .customer,
      .provider,
      .item,
      .quantity,
      .unit,
      .date,
      .account,
      .sum {
        max-height: 44px;
        position: relative;
        z-index: 0;
        overflow: visible;
        &:hover {
          z-index: 1;
        }
      }
      .id {
        color: $light-gray;
      }
      .accountNumber {
        color: $accent;
        cursor: pointer;
        &:hover {
          color: #22a2ff;
        }
      }

      .account {
        color: #1767a2;
      }

      .quantity,
      .unit {
        color: $dark-gray;
      }

      .hide-block {
        width: 300px;
        display: none;
        background-color: white;
        position: absolute;
        border: 1px solid $light-gray;
        padding: 7px;
        right: -200px;
      }

      .customer.active:hover + .hide-block,
      .provider.active:hover + .hide-block,
      .item.active:hover + .hide-block,
      .quantity.active:hover + .hide-block,
      .unit.active:hover + .hide-block,
      .date.active:hover + .hide-block,
      .account.active:hover + .hide-block,
      .sum.active:hover + .hide-block {
        display: block;
      }
    }
  }
}
