.Lower-table {
	font-family: $default-font;
	font-size: 14px;
	margin-top: 37px;
	box-sizing: border-box;
	width: 1600px;
	//max-width: 1110px;
	//overflow-x: scroll;

	.Lower-table-popup {
		position: relative;
	}

	.hide {
		display: none;
	}

	select {
		&:disabled + .arrow {
			display: none;
		}
	}

	.nameContainer {
		position: relative;

		.material-not-save {
			border: 1px solid $red !important;
		}

		.completionBlock {
			position: relative;
		}

		.name {
			min-width: 600px;
			max-width: 600px;

			&:disabled {
				color: $text;
			}
		}

		.applications {
			min-width: 600px;
			max-width: 600px;
			display: none;
			position: absolute;
			top: 35px;
			left: 5px;
			z-index: 1000;
			background-color: white;
			border: 1px solid $light-gray;
			width: 180px;
			max-height: 100px;
			overflow: auto;

			.updateMaterial {
				color: $accent;
				cursor: pointer;
				text-align: center;
				margin-bottom: 10px;
			}

			&:hover {
				display: block;
			}
		}

		.addNewMaterial {
			color: $accent;
			cursor: pointer;
			text-align: center;
			padding: 5px;
		}

		.completion {
			padding: 5px;
			display: flex;
			align-items: center;
			justify-content: space-between;
			width: 100%;
			cursor: pointer;

			&:nth-child(2n) {
				background-color: $odd;
			}
			&:last-child {
				margin-bottom: 0;
			}
		}

		.show {
			display: block;
		}
	}

	.name {
		min-width: 600px;
		max-width: 600px;
	}

	.count {
		min-width: 80px;
		max-width: 80px;
	}

	.materialId {
		min-width: 100px;
		max-width: 100px;
	}

	.dateAdding,
	.dateDelivery {
		min-width: 145px;
		max-width: 145px;
		position: relative;

		.calendar {
			height: 22px;
			position: absolute;
			left: -35px;
			top: 5px;
		}
	}

	.stateId {
		min-width: 105px;
		max-width: 105px;
	}

	.link {
		min-width: 155px;
		max-width: 155px;

		.link-name {
			color: $accent;
			min-width: 80px;
			max-width: 80px;
			margin-right: 10px;
			height: 100%;
			display: none;
		}

		.link-url {
			min-width: 140px;
			max-width: 140px;
			height: 100%;
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;
			position: relative;
			top: -10px;
		}

		label {
			height: 100%;
		}
	}

	.weight {
		min-width: 80px;
		max-width: 80px;
	}

	.volume {
		min-width: 100px;
		max-width: 100px;
	}

	.name,
	.count,
	.materialId,
	.dateAdding,
	.dateDelivery,
	.stateId,
	.link-name,
	.link-url,
	.weight,
	.volume,
	.positionPurchase,
	.claimed,
	.inStock,
	.buy,
	.unitPurchase,
	.accountNumber,
	.dateOfPayment,
	.numberStock,
	.available,
	.return,
	.released,
	.unitStock,
	.stateStock,
	.positionStock {
		padding: 0 5px;
	}

	.warning {
		border: 1px $red solid !important;
	}

	input {
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
		background-color: transparent;
		font-size: 14px;
		font-family: $default-font;
		line-height: 16px;
		outline: none;
		border: 1px transparent solid;
		-moz-transition: all 0.2s;
		-webkit-transition: all 0.2s;
		-o-transition: all 0.2s;
		transition: all 0.2s;
	}

	.cell {
		height: 30px;
		display: flex;
		align-items: center;
		margin: 0 5px 20px;
	}

	.arrow {
		z-index: -1;
		position: absolute;
		right: 15px;
		top: 13px;
		width: 12px;
		height: 6px;
		background-color: $text;
		-moz-transition: all 0.2s;
		-webkit-transition: all 0.2s;
		-o-transition: all 0.2s;
		transition: all 0.2s;
	}

	.arrow-active + .arrow {
		transform: rotate(180deg);
	}

	.materialId {
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
		border: none;
		background-color: transparent;
		font-size: 14px;
		line-height: 16px;
		outline: none;
		cursor: pointer;
	}

	.Lower-table-header {
		display: flex;
		border-bottom: 1px solid $light-gray;
		color: $dark-gray;
		margin: 0 -12px 25px -25px;
		padding: 0 0 10px 10px;
		align-items: center;
		width: 1620px;

		.materialId {
			cursor: text;
		}

		div {
			margin: 0 5px;
			padding-left: 0;
		}
	}

	.Lower-table-content {
		color: $text;
		box-sizing: border-box;
		margin: 0 -19px;

		.positionsInfo {
			width: 100%;
			display: flex;
			justify-content: center;
			color: $text;
			margin-top: 15px;
		}

		.Lower-table-row-content {
			position: relative;

			.removePosition-block {
				// position: absolute;
				height: 30px;
				display: flex;
				align-items: center;
				width: 30px;
				top: 0px;
				// margin-top: 10px;

				.removePosition {
					width: 10px;
					height: 10px;
					cursor: pointer;
					display: none;
					margin: 0 auto;

					&:hover {
						background-color: $red;
					}
				}
			}

			&:hover {
				.removePosition-block {
					.removePosition {
						display: block;
					}
				}
			}

			.nameContainer {
				.completionBlock {
					display: flex;
					align-items: center;
					&:nth-child(2n) {
						background-color: $odd;
					}
				}

				.removePosition-block {
					top: 5px;
					right: 5px;
					height: 20px;
					width: 20px;
					margin-top: 0;
					display: flex;
					align-items: center;
				}
			}

			&:hover {
				.Lower-table-row-notes {
					visibility: visible;
					opacity: 1;
					transition-delay: 0.5s;
				}

				input {
					border: 1px $light-gray solid;
				}
			}

			&.disabled {
				.Lower-table-row {
					.date {
						input:hover {
							cursor: unset;
						}
					}
				}
			}

			.Lower-table-row {
				display: flex;

				.Lower-table-cell {
					position: relative;
					box-sizing: border-box;
				}

				input:focus {
					border: 1px $accent solid;
				}

				select {
					border: 1px transparent solid;
					-moz-transition: all 0.2s;
					-webkit-transition: all 0.2s;
					-o-transition: all 0.2s;
					transition: all 0.2s;
				}

				.dateDelivery {
					color: $accent;
					padding-left: 0;
				}

				.link,
				.state-blue {
					color: $accent;
				}

				.state-green {
					color: $green;
				}

				.state-yellow {
					color: #ffb40a;
				}

				.state-gray {
					color: $dark-gray;
				}

				.Input {
					height: 100%;
					display: inline-block;

					.date {
						position: relative;
						height: 100%;

						::-webkit-calendar-picker-indicator {
							cursor: pointer;
							// background: url(../svg/calendar.svg) no-repeat center;
							color: transparent;
							opacity: 1;
							padding-right: 0;
							margin-right: 0;
						}

						input {
							width: 130px;
							cursor: pointer;
							height: 30px;
							padding: 5px 0 5px 5px;

							&:valid:not(:focus) + span {
								display: flex;
								align-items: center;
							}

							&:disabled:valid + span {
								display: flex;
							}

							&:invalid {
								color: $light-gray;
							}
						}

						span {
							display: none;
							position: absolute;
							bottom: -7px;
							right: -8px;
							width: 140px;
							height: 31px;
							font-size: 14px;
							line-height: 21px;
							background-color: white;
							color: $accent;
							border: 1px solid transparent;
							padding-left: 5px;
						}

						input:disabled + span {
							bottom: -7px;
							display: flex;
							align-items: center;
						}
					}
				}
			}

			.Lower-table-row-notes {
				-moz-transition: all 0.2s;
				-webkit-transition: all 0.2s;
				-o-transition: all 0.2s;
				transition: all 0.2s;
				position: absolute;
				z-index: 3;
				width: 979px;
				visibility: hidden;
				transform: translate3d(190px, 0, 0);
				opacity: 0;
				margin: -15px -40px 25px 5px;
				display: flex;
				transition-delay: 0s;
				right: 274px;

				textarea {
					width: 979px;
					height: 100px;
					border: 1px $light-gray solid;
					padding: 5px;
					font-family: $default-font;
					font-size: 14px;
					resize: none;

					&:focus {
						outline: none;
						border: 1px $accent solid;
						opacity: 1;
						visibility: visible;
					}
				}
			}
		}
	}

	.hidden {
		display: none;
	}

	.lower-table-up {
		position: relative;

		.nameContainer {
			.material-not-save {
				border: 1px solid $light-gray;
			}
		}

		.updateMaterial {
			display: none;
		}

		.Lower-table-button {
			.button {
				position: absolute;
				display: flex;
				color: #5164c7;
				z-index: 6;
				width: 1640px;
				height: 30px;
				align-items: center;
				justify-content: center;
			}

			span {
				cursor: pointer;
				padding: 30px;
				margin-right: 140px;
				background-color: white;
				height: 30px;
				display: flex;
				align-items: center;
			}
		}

		.Lower-table-row-content {
			filter: blur(5px);

			.link-name {
				min-width: 80px;
				max-width: 80px;
			}

			.link-url {
				min-width: 140px;
				max-width: 140px;
				position: relative;
				top: 0;
			}

			.Lower-table-row {
				display: flex;
				height: 30px;
				margin: 0 0 20px;

				input {
					border: 1px $light-gray solid;
					font-size: 14px;
				}

				select {
					border: 1px $light-gray solid;
					user-select: none;

					&:disabled {
						border: none;
					}

					&:disabled + .arrow {
						display: none;
					}
				}

				.link {
					display: flex;

					label {
						display: flex;
						height: 100%;
					}
				}

				div {
					-webkit-touch-callout: none;
					-webkit-user-select: none;
					-khtml-user-select: none;
					-moz-user-select: none;
					-ms-user-select: none;
					user-select: none;
				}

				.dateAdding,
				.dateDelivery {
					border: none;

					.calendar {
						background-color: transparent;
					}

					.Input {
						border: none;
						height: 100%;
						label {
							input {
								border: none;
								color: $accent;
							}
						}
					}
				}
			}
		}

		.active {
			filter: none;

			.removePosition {
				display: flex !important;
			}

			.nameContainer {
				.removePosition-block {
					display: flex;
				}
			}

			.Lower-table-row-notes {
				visibility: visible;
				opacity: 1;
				transition-delay: 0.5s;
			}

			.name:invalid,
			.count:invalid {
				border-color: $red;
			}

			.name:valid,
			.count:valid {
				border-color: $green;
			}

			&:hover {
				.name,
				.count {
					border-color: orange;
				}

				.name:valid,
				.count:valid {
					border-color: $green;
				}
			}

			.name:focus:valid,
			.count:focus:valid {
				border-color: $accent;
			}
		}
	}
}
.dateAdding.cell {
	color: $light-gray;
}
.View-Application {
	.Lower-table {
		.materialId {
			cursor: default;
		}

		.arrow {
			display: none;
		}

		input:disabled {
			cursor: default !important;
		}

		div {
			cursor: default !important;
		}

		.Lower-table-content {
			.Lower-table-row-content {
				&:hover {
					.Lower-table-row-notes {
						background-color: white;
					}

					input {
						border: 1px transparent solid;
					}
				}

				.Lower-table-row .Input .date span {
					display: block;
					bottom: -10px;
				}
			}
		}
	}
}
.account-none.cell {
	width: 90px;
	display: flex;
	color: $light-gray;
	justify-content: center;
}

.Creating {
	.Lower-table-header {
		.materialId {
			cursor: pointer !important;
			color: $accent;
		}
	}
}

.Lower-table-container {
	max-width: 1600px;
}

.Request-purchase-table {
	max-width: 1500px;

	.nameContainer {
		max-width: 650px !important;
		min-width: 650px !important;
		input {
			max-width: 650px !important;
			min-width: 650px !important;
		}
	}

	.name {
		min-width: 640px !important;
		max-width: 640px !important;
	}
}

.Request-stock-table {
	max-width: 1600px;

	.nameContainer {
		max-width: 650px !important;
		min-width: 650px !important;
		input {
			max-width: 650px !important;
			min-width: 650px !important;
		}
	}

	.name {
		min-width: 640px !important;
		max-width: 640px !important;
	}

	.stateId {
		min-width: 190px !important;
		max-width: 190px !important;
	}
}
