.Acc-tab {
  margin-top: 40px;

  // input:active {
  //   border: 1px solid #cdcdcd !important;
  // }

  Input {
    .top {
      display: none;
    }
  }
  .title-acc {
    display: flex;
    justify-content: space-around;
    color: $light-gray;
    font-size: 14px;
    padding-bottom: 20px;
    padding-left: 40px;
    padding-right: 40px;
    .id {
      width: 40px;
      line-height: 21px;
    }

    .accountNumber {
      width: 90px;
      line-height: 21px;
    }

    .dateAccount {
      width: 130px;
      line-height: 21px;
    }

    .invoiceAmount {
      width: 120px;
      line-height: 21px;
    }

    .client {
      width: 250px;
      line-height: 21px;
      padding-right: 40px;
    }

    .supplier {
      width: 250px;
      line-height: 21px;
    }
  }

  .Acc-alter {
    & > div:nth-child(2n + 1) {
      background-color: $odd;
    }
    .Acc-hide {
      position: relative;

      .Addit-Info {
        position: absolute;
        justify-content: center;
        align-items: center;
        display: flex;
        border: 1px solid $light-gray;
        padding: 5px;
        top: 50px;
        right: 380px;
        z-index: 3;
        background-color: $odd;
        .Addit-Text {
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 16px;
          padding-right: 40px;
        }
        .Addit-Date {
          width: 212px;
          height: 36px;
          border: 1px solid #cdcdcd;
          box-sizing: border-box;
          margin-right: 20px;
        }
        .Button {
          @include Button;
          width: 101px;
          height: 36px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 14px;
        }
      }
      .Acc-Str {
        display: flex;
        justify-content: space-around;
        font-size: 14px;
        align-items: center;
        height: 70px;
        width: 100%;
        padding-left: 40px;
        padding-right: 40px;

        .id .accountNumber .dateAccount .invoiceAmount .client .supplier {
          max-height: 44px;
          position: relative;
          z-index: 0;
          overflow: visible;
          &:hover {
            z-index: 1;
          }
        }

        .hide-block {
          width: 300px;
          display: none;
          background-color: white;
          position: absolute;
          border: 1px solid $light-gray;
          padding: 7px;
          right: -200px;
        }

        .id.active:hover + .hide-block,
        .accountNumber.active:hover + .hide-block,
        .dateAccount.active:hover + .hide-block,
        .invoiceAmount.active:hover + .hide-block,
        .client.active:hover + .hide-block,
        .supplier.active:hover + .hide-block {
          display: block;
        }

        &:hover {
          background-color: #f5f7ff;
          cursor: pointer;
        }

        .id {
          width: 40px;
          line-height: 21px;
          color: $light-gray;
        }

        .accountNumber {
          width: 90px;
          line-height: 21px;
        }

        .dateAccount {
          width: 130px;
          line-height: 21px;
        }

        .invoiceAmount {
          width: 120px;
          line-height: 21px;
        }

        .client {
          width: 250px;
          line-height: 21px;
          padding-right: 40px;
        }

        .supplier {
          width: 250px;
          line-height: 21px;
        }

        .Acc-cell {
          min-height: 70px;
          display: flex;
          align-items: center;
        }
      }
    }
  }
}
