.Users-List {
  display: flex;
  align-items: center;
  flex-direction: column;
  .Button {
    color: $dark-gray;
    width: fit-content;

    &:hover {
      color: $accent;
      cursor: pointer;
    }
  }
  .Main-table {
    .title {
      grid-template-columns: 2fr 4fr 2fr 4fr;
      grid-column-gap: 20px;
      align-items: center;
      color: $light-gray;
      font-size: 14px;
      margin-top: 40px;
      margin-bottom: 20px;
      padding: 0px 20px;
    }
    .Main-Str {
      grid-template-columns: 2fr 4fr 2fr 4fr;
      grid-column-gap: 20px;
      padding: 0px 20px;
      align-items: center;
      font-size: 14px;
      background-color: white;
      cursor: pointer;
      height: 50px;
      width: 100%;
      &:hover {
        background-color: $select;
      }
    }
  }
}
