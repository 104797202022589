.Request-purchase,
.Request-purchase-table {
  input[type="date"]:disabled + span {
    visibility: visible;
    padding-left: 0;
    cursor: text;
  }

  .autocomplete {
    display: none;
    position: absolute;
    z-index: 1000;
    width: fit-content;
    background-color: white;
    color: $text;
    border: 1px solid $light-gray;
    box-shadow: 0px 20px 60px rgba(0, 0, 0, 0.1);
    height: fit-content;
    max-height: 250px;
    overflow: auto;
    top: 65px;

    .completion {
      display: flex;
      justify-content: space-between;
      align-items: center;
      min-height: 40px;
      height: fit-content;
      padding-right: 14px;
      padding-left: 14px;

      &:hover {
        background-color: $odd;
        cursor: pointer;

        .options {
          .edit,
          .delete {
            color: $dark-gray;
          }

          .edit:hover {
            color: $accent;
          }

          .delete:hover {
            color: $red;
          }
        }
      }

      .text {
        width: 300px;
        padding-top: 8px;
        padding-bottom: 8px;
      }

      .options {
        display: flex;
        justify-content: space-between;
        width: 180px;
        margin-left: 10px;
        font-size: 14px;
        line-height: 16px;

        .edit,
        .delete {
          color: transparent;
        }
      }
    }

    &:hover {
      display: block;
    }

    &:empty {
      border: none;
    }
  }

  .acc-Cust-title,
  .acc-Sup-title {
    position: relative;

    .add-to-base {
      position: absolute;
      top: 0;
      right: 0;
      cursor: pointer;
      -moz-transition: all 0.2s;
      -webkit-transition: all 0.2s;
      -o-transition: all 0.2s;
      transition: all 0.2s;

      &:hover {
        color: $accent;
      }
    }
  }

  .acc-Sup:focus + .autocomplete {
    display: block;
  }

  .acc-Cust:focus + .autocomplete {
    display: block;
  }

  .Lower-table {
    .name {
      min-width: 340px;
      max-width: 340px;
    }
  }

  input:disabled {
    color: $text;
    border: none;
  }

  .count,
  .materialId {
    width: 100px;
  }

  .inStock {
    min-width: 85px;
    max-width: 85px;
  }

  .buy {
    min-width: 85px;
    max-width: 85px;
  }

  .link {
    min-width: 130px !important;
    max-width: 130px !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;

    &:disabled {
      border: none;
    }

    .link-url {
      min-width: 130px !important;
      max-width: 130px !important;
    }

    input {
      width: 100%;
      height: 100%;
    }
  }

  .dateAccount {
    min-width: 130px;
    max-width: 130px;
  }

  .countInput {
    margin-top: 15px;
  }

  .accountId,
  .attach {
    width: 125px;
  }

  .nameContainer {
    max-width: 350px !important;
    min-width: 350px !important;
    div {
      width: inherit;
    }

    input {
      max-width: 350px !important;
      min-width: 350px !important;
      margin-right: 15px;
    }
  }

  .document {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 18px;
    line-height: 21px;
    color: $dark-gray;
    cursor: pointer;

    &:hover {
      color: $text;

      .printer {
        background-color: $green;
      }
    }

    .printer {
      width: 29px;
      height: 29px;
      margin-right: 15px;
      background-color: $dark-gray;
    }
  }

  .Lower-table {
    .inStock {
      width: 100px;
    }

    .Lower-table-header {
      border-bottom: none;
      margin: 0 -12px 10px -15px;
      padding-bottom: 0;
      .name {
        width: 340px !important;
      }
    }

    .Lower-table-content {
      margin: 0px -10px;

      .link {
        input {
          color: $accent !important;
        }
      }

      .inStock {
        width: 100px !important;
        .zero {
          color: $green;
        }
      }

      .Lower-table-row-content {
        &:hover {
          .removePosition-block {
            .removePosition {
              display: none;
            }
          }

          input:disabled {
            border: none;
          }
        }

        .Select {
          .materialId {
            height: 100%;
            padding-top: 7px;
            cursor: text;
          }
        }

        .accountId,
        .attach {
          margin-left: 10px;
        }

        .dateDelivery {
          padding-left: 0;

          .date {
            top: -10px;

            span {
              right: -5px !important;
            }

            input {
              padding: 5px !important;
            }
          }
        }

        .Lower-table-row {
          height: 50px;
        }

        & > .Lower-table-row:nth-child(2n + 1) {
          background-color: $odd;
        }

        .buy {
          color: $accent;

          &-green {
            color: $green;
          }
        }

        .materialId {
          color: $dark-gray;
        }

        .accountId,
        .attach {
          color: $accent;
          cursor: pointer;
        }

        .accountId {
          display: flex;
          justify-content: space-between;
          padding-right: 40px;
        }

        .Lower-table-row-notes {
          textarea {
            display: none;
          }
        }

        .cell {
          margin-bottom: 5px;
          padding-bottom: 10px;
          padding-top: 10px;
        }

        .Lower-table-cell {
          display: flex;
          align-items: center;
        }
      }
    }
  }
}
