.Request-stock,
.Request-stock-table {
  .positionStock {
    width: 297px;
  }

  input:disabled {
    color: $text;
    border: none;
  }

  input[type="date"]:disabled + span {
    visibility: visible;
    padding-left: 0;
    cursor: text;
  }

  .count,
  .available,
  .buy,
  .return,
  .released,
  .materialId {
    width: 90px;
  }

  .inStock {
    width: 90px;
  }

  .nameContainer {
    width: 311px;

    input {
      max-width: 300px !important;
      min-width: 300px !important;
    }
  }

  .attach,
  .accountId {
    width: 99px;
  }

  .stateId {
    width: 100px;
  }

  .document {
    display: flex;
    align-items: center;
    font-size: 18px;
    line-height: 21px;
    color: #969696;
    cursor: pointer;
    margin-top: 20px;

    &:hover {
      color: $text;

      .printer {
        background-color: $green;
      }
    }

    .printer {
      width: 29px;
      height: 29px;
      margin-right: 15px;
      background-color: $dark-gray;
    }
  }

  .Lower-table {
    .Lower-table-header {
      margin: 0 -10px;
      padding: 0 0 10px 5px;
    }

    .name {
      min-width: 300px;
      max-width: 300px;
    }

    .arrow {
      display: none;
    }

    .Lower-table-content {
      margin: 0px -10px;

      .Lower-table-row-content {
        -moz-transition: all 0.2s;
        -webkit-transition: all 0.2s;
        -o-transition: all 0.2s;
        transition: all 0.2s;

        &:hover {
          background-color: #edf0ff;

          .removePosition-block {
            .removePosition {
              display: none;
            }
          }

          input:disabled {
            border: none;
          }
        }

        .buy {
          color: $accent;

          &-green {
            color: $green;
          }
        }

        .materialId {
          color: $dark-gray;
          cursor: text;
        }

        .attach,
        .accountId {
          color: $accent;
          -moz-transition: all 0.2s;
          -webkit-transition: all 0.2s;
          -o-transition: all 0.2s;
          transition: all 0.2s;
          cursor: pointer;

          &:hover {
            color: #22a2ff;
          }
        }

        .accountId {
          display: flex;
          justify-content: center;
          margin-left: 0;
        }

        .accountIdNull {
          color: $dark-gray;
          display: flex;
          justify-content: center;
          margin-left: 0;
          cursor: default;

          &:hover {
            color: $dark-gray;
          }
        }

        .Lower-table-row-notes {
          textarea {
            display: none;
          }
        }
      }

      .cell {
        margin-top: 10px;
        margin-bottom: 10px;
        padding-bottom: 5px;
        padding-top: 5px;
      }
    }
  }
}
