.RightsPopup,
.DeletePositionsPopup,
.NameInputPopup,
.InformationPopup,
.accountCreatingCard,
.StoragePopup,
.RemoveMaterialPopup,
.AddNewMaterialPopup,
.EditCustomerPopup,
.DeleteCustomerPopup,
.UnitEditPopup,
.ChangeUnitPopup,
.DeleteUnitPopup,
.RegionPopup,
.ChangeRegionPopup,
.DeleteRegionPopup {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 1000;
  visibility: hidden;
  transition: visibility 0.4s ease 0s;

  input:focus {
    border: 1px solid $accent !important;
  }

  &.show {
    visibility: visible;

    .window {
      top: 50%;
      transition: top 0.4s ease 0s;
    }
  }

  .Select {
    position: relative;

    select {
      width: 90px;
      font-size: 14px;
      line-height: 16px;
      color: $text;
      padding: 8px 0 8px 14px;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      border: 1px solid $light-gray;
      border-radius: 0;
      background-color: transparent;
      cursor: pointer;
      outline: none;
    }

    .arrow {
      z-index: -1;
      position: absolute;
      left: 70px;
      top: 15px;
      width: 11px;
      background-color: $dark-gray;
    }
  }

  .nameInputPopup {
    .title {
      margin-bottom: 10px;
    }
    input {
      padding: 6px;
      width: 100%;
    }
  }

  .selectTitle {
    margin: 15px 0;
  }

  .window {
    position: absolute;
    top: -20%;
    left: 50%;
    padding: 45px;
    background-color: white;
    transform: translate(-50%, -50%);
    transition: top 0.4s ease 0s;
    font-size: 14px;

    .rightsPopup__title {
      font-size: 18px;
      color: $text;
      font-weight: bold;
      margin-bottom: 15px;
    }

    .rightsPopup__text {
      span {
        color: $text;
        font-weight: bold;
      }
    }

    .rightsPopup__buttons {
      margin-top: 20px;
      display: flex;
      justify-content: space-between;

      .rightsPopup__buttons-true {
        color: $dark-gray;
        cursor: pointer;
      }

      .rightsPopup__buttons-false {
        color: $accent;
        cursor: pointer;
      }
    }
  }
}

.AddNewMaterialPopup {
  .rightsPopup__buttons-false {
    color: $dark-gray !important;
  }

  .rightsPopup__buttons-true {
    color: $accent !important;
  }
}

.InformationPopup {
  .window {
    padding: 20px 40px;
    width: 610px;

    .title {
      font-size: 18px;
      color: $text;
      font-weight: bold;
      margin-bottom: 10px;
    }

    .popup-body {
      display: flex;

      .cell-popup {
        display: flex;

        &:first-child {
          width: 200px;
          margin-right: 40px;
        }

        &:last-child {
          width: 350px;
        }

        .column {
          padding-right: 30px;
          width: 100%;

          &:last-child {
            padding-right: 0;
          }

          .subtitle {
            font-size: 14px;
            color: $text;
            font-weight: bold;
            margin-bottom: 10px;
          }

          .cell-popup-name {
            margin-bottom: 10px;
            color: $dark-gray;
          }

          .delivery-date {
            margin-top: 17px;
            color: $red;
          }

          .Input {
            height: 35px;

            label {
              position: relative;

              input {
                border: 1px solid $light-gray;
                height: 100%;
                padding-left: 5px;
                cursor: pointer;
              }

              span {
                display: none;
                position: absolute;
                bottom: -9px;
                right: 0;
                width: 100%;
                height: 35px;
                font-size: 14px;
                line-height: 21px;
                background-color: white;
                color: $text;
                border: 1px solid $light-gray;
                padding-left: 5px;
                cursor: pointer;
              }

              input:valid:not(:focus) + span {
                display: flex;
                align-items: center;
              }
            }
          }
        }

        .without-subtitle {
          margin-top: 29px;
        }
      }
    }

    .popup-footer {
      display: flex;
      margin-top: 20px;
      justify-content: space-between;

      .popup-footer-button,
      .popup-footer-note,
      .popup-footer-cancel,
      .popup-footer-save,
      .popup-footer-unpin {
        color: $dark-gray;
      }

      .popup-footer-button {
        cursor: pointer;
      }

      .popup-footer-cancel {
        margin-left: 85px;
        cursor: pointer;
      }

      .popup-footer-save {
        cursor: pointer;
      }

      .popup-footer-unpin {
        cursor: pointer;
        margin-right: 35px;
      }
    }
  }
}

.EditCustomerPopup {
  .Input {
    margin-top: 15px;
    input {
      font-size: 14px;
      line-height: 21px;
      color: $text;
      width: 100%;
      border: 1px solid $light-gray;
      outline: none;
      padding: 8px 0 8px 14px;
      &:focus {
        border-color: $accent;
      }

      &:invalid {
        box-shadow: none;
      }

      &:disabled {
        background-color: white;
        border-color: transparent;
      }

      &::placeholder {
        color: $light-gray;
      }
    }
  }
}

.DeleteCustomerPopup {
  span {
    font-weight: bold;
  }
}

.UnitEditPopup,
.RegionPopup {
  input {
    border: 1px solid $light-gray;
    height: 34px;
    padding: 5px;
  }

  .rightsPopup__title {
    display: flex;
    justify-content: space-between;
  }

  .rightsPopup__buttons-true {
    color: $dark-gray;
    cursor: pointer;
    font-size: 16px;

    &:hover {
      color: $accent;
    }
  }

  .rightsPopup__content {
    display: flex;

    .unitEditAdd {
      align-items: center;
      display: flex;
      margin-right: 100px;

      &__button {
        height: 34px;
        display: flex;
        align-items: center;
        font-size: 16px;
        padding: 15px;
        border: 1px solid $light-gray;
        border-left: none;
        cursor: pointer;
        -moz-transition: all 0.2s;
        -webkit-transition: all 0.2s;
        -o-transition: all 0.2s;
        transition: all 0.2s;

        &:hover {
          background-color: $green;
          color: white;
          border: 1px solid $green;
          border-left: none;
        }
      }
    }

    .unitEditChange {
      display: flex;
      align-items: center;

      &__button-change,
      &__button-delete {
        -moz-transition: all 0.2s;
        -webkit-transition: all 0.2s;
        -o-transition: all 0.2s;
        transition: all 0.2s;
        cursor: pointer;
      }

      &__button-change {
        margin: 0 15px;
        &:hover {
          color: $accent;
        }
      }

      &__button-delete {
        &:hover {
          color: $red;
        }
      }
    }
  }
}

.ChangeUnitPopup,
.ChangeRegionPopup {
  .rightsPopup__content {
    display: flex;
    align-items: center;
  }

  span {
    margin: 0 5px;
    font-weight: bold;
  }

  .top {
    margin: 0 !important;
  }

  input {
    margin-left: 10px;
    border: 1px solid $light-gray;
    height: 30px;
    padding: 5px;
    font-size: 14px !important;

    &:focus {
      border: 1px solid $accent;
    }
  }
}

.DeleteUnitPopup,
.DeleteRegionPopup {
  input {
    font-size: 14px !important;
  }

  span {
    font-weight: bold;
  }
}

.RegionPopup {
  .window {
    width: fit-content;
  }

  .rightsPopup__title {
    display: flex;
    justify-content: space-between;

    .rightsPopup__buttons-true {
      color: $dark-gray;
      cursor: pointer;
      font-size: 16px;

      &:hover {
        color: $accent;
      }
    }
  }

  input {
    font-size: 14px !important;
  }

  .arrow {
    top: -1px !important;
    left: 230px !important;
  }

  .unitEditAdd {
    .top {
      margin: 0;
    }
  }

  select {
    width: 250px !important;
  }
}
