.Authorization {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: #f5f5f5;

  .window {
    position: fixed;
    top: 50%;
    left: 50%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 370px;
    height: 343px;
    padding: 60px;
    padding-top: 50px;
    transform: translate(-50%, -50%);
    background-color: white;
    border-radius: 8px;

    .Title {
      margin-bottom: 20px;
    }

    .Input {
      margin-bottom: 20px;

      input {
        padding: 14px 0 14px 20px;
        font-size: 16px;
        border: 1px solid $light-gray;
        outline: none;
        width: 250px;
        border-radius: 8px;

        &:focus {
          border-color: $accent;
        }

        &:invalid {
          box-shadow: none;
        }

        &:disabled {
          background-color: white;
          border-color: transparent;
        }

        &::placeholder {
          color: $light-gray;
        }
      }
    }

    .Button {
      @include Button;
      padding: 0.8em 20px;
      border-radius: 8px;
      font-size: 16px;
    }
  }
}
