@import "_mixins/common";

$default-font: "roboto-example";

$text: #242424;
$dark-gray: #969696;
$light-gray: #cdcdcd;
$accent: #5164c7;
$green: #3e9a4d;
$red: #e31e1e;
$select: #f5f7ff;
$odd: #fcfcfc;

$icon_arr: ("close", "arrow", "tick", "excel", "calendar", "printer");

// Подгружаем все иконки по миксину в common.scss
@each $icon in $icon_arr {
  .#{$icon} {
    @include icon(40, 40, #{$icon});
  }
}
