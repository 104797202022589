.StoragePopup {
  // display: none;
  // border: 1px $light-gray solid;
  // padding: 10px;
  // position: absolute;
  // z-index: 1000;
  // background-color: white;
  // top: 50px;
  // left: 50%;
  // -moz-transform: translate(-50%, 0);
  // -ms-transform: translate(-50%, 0);
  // -webkit-transform: translate(-50%, 0);
  // -o-transform: translate(-50%, 0);
  // transform: translate(-50%, 0);

  &--show {
    display: block;
  }

  .window {
    // width: fit-content;
  }

  .Input {
    .top {
      margin-bottom: 5px;
      color: $light-gray;
      font-size: 14px;

      .title {
        display: block;
        margin: 0;
        padding: 0;
      }
    }

    input:disabled {
      font-size: 16px;
      border: none;
      background-color: white;
      color: $text;
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  &__title,
  &__exit {
    font-weight: bold;
    margin-bottom: 20px;
    font-size: 16px;
  }

  &__exit:hover {
    cursor: pointer;
    color: $accent;
  }

  &__fields {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 50px 50px;
    grid-gap: 20px;
    margin-bottom: 20px;
  }

  &__positions {
    max-height: 300px;
    overflow-y: auto;
    .titles {
      display: grid;
      grid-template-columns: 2fr 150px 100px 100px 100px;
      // font-weight: bold;
      color: $dark-gray;
      font-size: 16px;

      &__count,
      &__returned,
      &__released,
      &__name,
      &__date {
        border: 1px solid #ececec;
        padding: 7px;
      }

      &__date {
        input {
          width: 142px;
        }
      }
    }
    .position {
      display: grid;
      grid-template-columns: 2fr 150px 100px 100px 100px;

      &__count,
      &__returned,
      &__released {
        input {
          width: 90px;
        }
      }

      &__count,
      &__returned,
      &__released,
      &__name,
      &__date {
        border: 1px solid #ececec;
        padding: 6px;
      }

      &__date {
        input {
          width: 142px;
        }
      }
    }
  }
}
