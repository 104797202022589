.Edit {
  position: relative;

  .rightsButton {
    margin-top: 30px;
    display: flex;
    align-items: center;

    .buttonDelete {
      cursor: pointer;
      margin-left: 29px;
      -moz-transition: all 0.2s;
      -webkit-transition: all 0.2s;
      -o-transition: all 0.2s;
      transition: all 0.2s;
      color: $dark-gray;

      &:hover {
        color: $red;
      }
    }
  }

  .Title {
    margin-top: 51px;
    margin-right: 100px;
  }

  .Input {
    .top {
      display: flex;
      justify-content: flex-start;
      width: 100%;
      font-size: 18px;
      line-height: 21px;
      color: $light-gray;
      margin-bottom: 4px;
    }

    input {
      font-size: 18px;
      line-height: 21px;
      color: $text;
      width: 100%;
      border: 1px solid $light-gray;
      outline: none;
      padding: 8px 0 8px 14px;
      &:focus {
        border-color: $accent;
      }

      &:invalid {
        box-shadow: none;
      }

      &:disabled {
        background-color: white;
        border-color: transparent;
      }

      &::placeholder {
        color: $light-gray;
      }
    }
  }

  .login,
  .email,
  .name {
    margin-top: 30px;

    .Input {
      width: 200px;
    }
  }

  .login {
    margin-right: 20px;
  }

  .addRegions {
    margin-top: 23px;
    width: 400px;

    .title {
      margin-right: 27px;
    }

    .editRegion {
      color: $accent;
      cursor: pointer;
    }

    .Input {
      position: relative;

      .top {
        position: relative;

        .addRegion {
          position: absolute;
          right: -13px;
          top: 41px;
          line-height: 16px;
          cursor: pointer;
          user-select: none;

          &:hover {
            color: $accent;
          }
        }
      }

      select {
        font-size: 18px;
        line-height: 21px;
        color: $text;
        padding: 12px 100px 12px 32px;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        border: 1px solid $light-gray;
        border-radius: 0;
        background-color: transparent;
        cursor: pointer;
        outline: none;
      }

      .arrow {
        z-index: -1;
        position: absolute;
        right: 133px;
        top: 32px;
        width: 11px;
        background-color: $light-gray;
      }
    }
  }

  .regions {
    margin-top: 20px;

    .addedRegions {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      flex-wrap: wrap;
      width: 500px;
      margin-top: 16px;

      .region {
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        margin-right: 35px;
        margin-bottom: 20px;
        border-radius: 25px;
        background-color: $odd;
        color: $text;
        font-size: 18px;
        line-height: 21px;
        padding: 10px 30px 10px 20px;

        .removeRegion {
          position: absolute;
          right: 15px;
          width: 10px;
          cursor: pointer;
          user-select: none;

          &:hover {
            background-color: $red;
          }
        }
      }
    }
  }

  .Button {
    @include Button;
    background-color: white;
    outline: none;

    &:disabled {
      border: 1px solid $dark-gray;
      color: $dark-gray;
    }
  }
}
