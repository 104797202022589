.UserEdit, .UserCreating {
  height: 730px;

  .row {
    height: 680px;
  }
  
  .Title {
    display: inline-block;
    width: fit-content;
    margin-top: 25px;
    margin-right: 70px;
  }
}