.Request {
	color: $text;
	position: relative;

	.arrow-active + .arrow {
		transform: rotate(180deg);
	}

	.filter {
		display: flex;
		justify-content: flex-start;
		align-items: flex-end;
		margin-top: 30px;
		font-size: 14px;
		line-height: 16px;
		margin-bottom: 30px;

		.Input {
			position: relative;

			.top {
				color: $light-gray;
				margin-bottom: 5px;
			}

			.arrow {
				z-index: -1;
				position: absolute;
				right: 0;
				top: 28px;
				width: 12px;
				height: 6px;
				background-color: $text;
				-moz-transition: all 0.2s;
				-webkit-transition: all 0.2s;
				-o-transition: all 0.2s;
				transition: all 0.2s;
			}

			select {
				width: 100%;
				-webkit-appearance: none;
				-moz-appearance: none;
				appearance: none;
				border: none;
				background-color: transparent;
				font-size: 14px;
				line-height: 16px;
				outline: none;
				cursor: pointer;
			}
		}

		.sort-select {
			width: 200px;
		}

		.search-select {
			margin-left: 42px;
			width: 165px;
		}

		.search {
			position: relative;
			top: 4px;
			margin-left: 30px;
			width: 214px;
			color: $dark-gray;
			font-size: 14px;
			line-height: 16px;

			input {
				border: none;
				border-bottom: 1px solid $light-gray;
				width: 100%;
				font-size: 14px;
				line-height: 20px;
				outline: none;

				&:focus {
					border-color: $accent;
				}
			}
		}

		.create-new-request,
		.archive {
			cursor: pointer;
			margin-left: 50px;
			text-decoration: none;
			line-height: 14px;

			&:hover {
				color: $accent;
			}
		}

		.create-new-request {
			color: $text;
		}

		.archive {
			color: $dark-gray;
		}
	}

	fieldset {
		margin: 0;
		padding: 0;
		border: none;
		font-size: 14px;
		color: $text;

		legend {
			display: flex;
		}

		.color-description {
			display: flex;
			align-items: center;
			margin-right: 15px;

			.color {
				height: 15px;
				width: 15px;
				border-radius: 50%;
				margin-right: 5px;
			}
		}
	}

	.Requests-Table {
		position: relative;
		right: 50px;
		margin-top: 25px;
		font-size: 14px;
		line-height: 16px;
		width: calc(100% + 50px);

		.request,
		.col-description {
			display: flex;
			height: 74px;
			position: relative;

			.Print-String {
				position: absolute;
				display: none !important;
				top: 100%;
				visibility: hidden;
				right: 0;
				z-index: 1000;
				background-color: $select;
				display: flex;
				color: $accent;
				padding: 10px 20px;

				.Print-Button,
				.Back-Button {
					color: $dark-gray;
					cursor: pointer;

					&:hover {
						color: $accent;
					}
				}

				.Print-Button {
					margin-right: 25px;
				}
			}

			// &:nth-child(2n + 1) {
			//   background-color: $odd;
			// }

			&:hover {
				// background-color: $select;
				cursor: pointer;
				.Print-String {
					visibility: visible;
				}
			}

			.index {
				display: flex;
				justify-content: center;
				align-items: center;
				width: 50px;
				color: $dark-gray;
			}

			.title {
				width: 270px;
				padding-top: 8px;
				padding-bottom: 8px;

				.top {
					display: flex;
					justify-content: flex-start;
					margin-bottom: 5px;

					.date {
						width: 130px;
						// color: $gray;
					}

					.status {
						color: $accent;
						margin-left: 25px;
					}

					.buyStatus {
						color: $light-gray;
						margin-left: 25px;
					}

					.Status-purchasing {
						color: $light-gray;
						margin-left: 25px;
					}
				}
			}

			.address,
			.company {
				display: flex;
				align-items: center;
				margin-left: 16px;
				width: 320px;
			}

			.number {
				display: flex;
				align-items: center;
				width: 150px;
				margin-left: 16px;
				word-break: break-all;
				padding-right: 5px;
			}
		}

		.state-1 {
			background-color: #d6ffdb;
		}
		.state-2 {
			background-color: $accent;
		}
		.state-8 {
			background-color: #f0d2ff;
		}
		.state-6 {
			background-color: #ffffc6;
		}
		.state-7 {
			background-color: #c4ceff;
		}
		.col-description {
			height: auto;
			color: $dark-gray;
			cursor: default;
		}
	}
}
