.Main {
  .content {
    background-color: #fbfbfb;
    padding: 45px;
    height: 120vh;

    .window {
      width: 586px;
      height: 642px;
      background-color: white;
      margin: 0 auto;
      border-radius: 4px;

      &__header {
        width: 100%;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
      }

      &__content {
        padding: 40px 85px 75px;
      }

      &__title {
        font-size: 40px;
        font-weight: bold;
        color: $text;
        margin-bottom: 30px;
      }

      .menu {
        display: flex;
        justify-content: space-between;

        &__left,
        &__right {
          display: flex;
          flex-direction: column;

          & > * {
            margin-bottom: 20px;
          }

          a {
            color: $text;
            text-decoration: none;
            font-size: 18px;

            &:hover {
              color: $accent;
            }
          }
        }

        &__title {
          font-size: 18px;
          color: $light-gray;
        }
      }
    }
  }
}
.href-delete {
  color: $light-gray;
  float: right;
  transition: color 100ms linear;
  &:hover {
    color: $red;
  }
}